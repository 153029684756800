import request from '@/utils/request'

export function getConfigDataFromFirebase() {
    return request({
        url: '/dynamicconfigs/getconfigdata',
        method: 'get'
    })
}

export function activateConfigDataFromFirebase(rowID, user) {
    return request({
        url: '/dynamicconfigs/activateconfigdata/' + rowID + "/" + user,
        method: 'get'
    })
}

export function deleteConfigDataFromFirebase(rowID, user) {
    return request({
        url: '/dynamicconfigs/deleteconfigdata/' + rowID + "/" + user,
        method: 'get'
    })
}

export function addConfigNameToFirebase(configNameData) {
    return request({
        url: '/dynamicconfigs/addconfigname',
        method: 'post',
        data: configNameData
    })
}

export function getConfigNameListFromFirebase() {
    return request({
        url: '/dynamicconfigs/getconfignamelist',
        method: 'get'
    })
}

export function deleteConfigNameFromFirebase(rowID) {
    return request({
        url: '/dynamicconfigs/deleteconfigname/' + rowID,
        method: 'get'
    })
}

export function uploadConfigFileToFirebase(formData, rowID, fromVersion, toVersion, user, fileName) {
    return request({
        url: '/dynamicconfigs/uploadconfig/' + rowID + "/" + fromVersion + "/" + toVersion + "/" + user + "/" + fileName,
        headers: { "content-type": "application/octet-stream" },
        method: 'put',
        data: formData,
    })
}