<template>
  <div class="app-container">
    <el-tabs v-model="activeNames" @tab-click="handleClick" v-loading="loading">
      <el-tab-pane label="Config List" name="1">
        <el-row :gutter="20">
          <el-col>
            <span>Last Modified: {{ modify }}</span>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input
              placeholder="Example: *, v:1.10.1, u:haoppd, n:IAP"
              v-model="searchString"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" icon="el-icon-search">Search</el-button>
          </el-col>
        </el-row>

        <el-table :data="configDataList" style="width: 100%">
          <el-table-column prop="id" label="ID" width="100px" sortable>
            <template slot-scope="{ row }">
              <span>{{ row.config_id }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Display Name" width="400px">
            <template slot-scope="{ row }">
              <span>{{ row.config_displayName }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Affect Game Version" width="200px">
            <template slot-scope="{ row }">
              <span>{{ row.config_affectGameVersion }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Upload Datetime">
            <template slot-scope="{ row }">
              <span>{{ row.config_datetime }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Bundle Name">
            <template slot-scope="{ row }">
              <span
                >{{ row.config_bundlename }} (<a
                  v-bind:href="CDNUrl + '/' + row.config_bundlename"
                  >Link</a
                >)</span
              >
            </template>
          </el-table-column>

          <el-table-column prop="uploader" label="Uploader" sortable>
            <template slot-scope="{ row }">
              <span>{{ row.config_uploader }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Actions">
            <template slot-scope="{ row }">
              <template v-if="row.config_activated">
                <el-button
                  type="success"
                  size="small"
                  icon="el-icon-edit-outline"
                  disabled
                  >Activated</el-button
                >
              </template>
              <template v-else>
                <el-button
                  type="success"
                  size="small"
                  icon="el-icon-edit-outline"
                  @click="onActivate(row)"
                  >Activate</el-button
                >
              </template>
              <template>
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  @click="onDeleteConfigData(row)"
                  >Delete</el-button
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane label="Add Config File" name="2">
        <el-form ref="form" label-width="200px">
          <el-form-item label="Browse file" style="width: 50%">
            <el-upload
              class="upload-demo"
              action=""
              ref="upload"
              :auto-upload="false"
              :multiple="false"
              :limit="1"
              :on-change="handleChange"
              :on-remove="handleRemove"
            >
              <el-button slot="trigger" size="small" type="primary"
                >Browse</el-button
              >
            </el-upload>
          </el-form-item>

          <el-form-item label="Display Name">
            <el-select v-model="selectedName" placeholder="Select">
              <el-option
                v-for="item in configNameList"
                :key="item.displayName"
                :label="item.displayName"
                :value="item.displayName"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Affect From Version" style="width: 50%">
            <el-input
              placeholder="Example: 1.10.0"
              v-model="fromVersion"
            ></el-input>
          </el-form-item>

          <el-form-item label="Affect To Version" style="width: 50%">
            <el-input
              placeholder="Example: 1.11.0"
              v-model="toVersion"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onUploadConfigFile"
              >Upload</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="Config Name List" name="3">
        <el-table :data="configNameList" style="width: 100%">
          <el-table-column label="Display Name">
            <template slot-scope="{ row }">
              <span>{{ row.displayName }}</span>
            </template>
          </el-table-column>

          <el-table-column label="File Type">
            <template slot-scope="{ row }">
              <span>{{ row.fileType }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Description">
            <template slot-scope="{ row }">
              <span>{{ row.description }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Actions">
            <template slot-scope="{ row }">
              <template>
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  @click="onDeleteConfigName(row)"
                  >Delete</el-button
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane label="Add Config Name" name="4">
        <el-form ref="form" :model="addConfigNameForm" label-width="200px">
          <el-form-item label="Display name">
            <el-input v-model="addConfigNameForm.displayName"></el-input>
          </el-form-item>
          <el-form-item label="File type">
            <el-select v-model="selectedFileType" placeholder="Select">
              <el-option
                v-for="item in fileTypeList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Description">
            <el-input v-model="addConfigNameForm.description"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onAddConfigName"
              >Add Config Name</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<style>
.el-row {
  margin-bottom: 20px;
}
</style>

<script>
import setting from "@/settings.js";
import { MessageBox, Message } from "element-ui";
import { isStringEmpty } from "@/utils/validate";
import {
  getConfigDataFromFirebase,
  deleteConfigDataFromFirebase,
  activateConfigDataFromFirebase,
  addConfigNameToFirebase,
  getConfigNameListFromFirebase,
  deleteConfigNameFromFirebase,
  uploadConfigFileToFirebase,
} from "@/api/dynamic_config";

import store from "@/store";
var fs = require("fs");

export default {
  name: "DynamicConfigs",
  components: {},
  data() {
    return {
      addConfigNameForm: {
        displayName: "",
        description: "",
      },

      configDataList: [],
      configNameList: [],
      fileTypeList: [
        "CONFIG_BUNDLE",
        "MACRO_BUNDLE",
        "LOCALIZE_BUNDLE",
        "CONFIG_ENDLESS_BUNDLE",
      ],
      loading: false,
      selectedName: "",
      selectedFileType: "",
      fromVersion: "",
      toVersion: "",
      uploadFile: undefined,
      searchString: "",
      activeNames: "1",
      modify: "Hello Admin!",
      log_error: "log_error",
      CDNUrl: setting.firebase_url + "/dynamicconfigs/downloadconfigdata",
    };
  },
  created() {
    this.loading = true;
    this.configDataList = [];
    this.configNameList = [];
    this.loading = false;
    this.modify = "Hello Admin!";

    this.getConfigDataList();
    this.getConfigNameList();
  },
  methods: {
    // get config data list
    getConfigDataList() {
      this.loading = true;
      this.configDataList = [];

      getConfigDataFromFirebase()
        .then((response) => {
          let fromDateTime = new Date(response.modify_datetime);
          this.modify = fromDateTime.toString() + " by " + response.modify_user;
          response.configs.forEach((i) => {
            fromDateTime = new Date(i.datetime);
            this.configDataList.push({
              config_id: i.id,
              config_displayName: i.display_name,
              config_affectGameVersion:
                i.affect_from_version + " - " + i.affect_to_version,
              config_datetime: fromDateTime.toLocaleString("en-GB", { hour12: false }),
              config_bundlename: i.filename,
              config_uploader: i.uploader,
              config_activated: i.activated,
            });
          });

          this.loading = false;
        })
        .catch((error) => {
          Message({
            message: "Can't fetch config data list",
            type: "error",
            duration: 5 * 1000,
          });
          this.loading = false;
        });
    },

    // activate config data
    onActivate(row) {
      let count = -1;
      let rowID = 0;

      this.configDataList.forEach((i) => {
        count++;
        if (i.config_id === row.config_id) {
          rowID = count;
        }
      });

      MessageBox.confirm(
        "Do you confirm activate this Config File: " +
          row.config_configName +
          "?",
        "Confirm activate",
        {
          confirmButtonText: "Activate",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.configDataList = [];
        this.loading = true;

        activateConfigDataFromFirebase(rowID, store.getters.email)
          .then((response) => {
            let fromDateTime = new Date(response.modify_datetime);
            this.modify =
              fromDateTime.toString() + " by " + response.modify_user;
            response.configs.forEach((i) => {
              fromDateTime = new Date(i.datetime);
              this.configDataList.push({
                config_id: i.id,
                config_displayName: i.display_name,
                config_affectGameVersion:
                  i.affect_from_version + " - " + i.affect_to_version,
                config_datetime: fromDateTime.toString(),
                config_bundlename: i.filename,
                config_uploader: i.uploader,
                config_activated: i.activated,
              });
            });

            Message({
              message: "Activate Config Data success!",
              type: "success",
              duration: 5 * 1000,
            });

            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },

    // delete config data
    onDeleteConfigData(row) {
      let count = -1;
      let rowID = 0;

      this.configDataList.forEach((i) => {
        count++;
        if (i.config_id === row.config_id) {
          rowID = count;
        }
      });

      MessageBox.confirm(
        "Do you confirm delete this Config File: " +
          row.config_configName +
          "?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.configDataList = [];
        this.loading = true;

        deleteConfigDataFromFirebase(rowID, store.getters.email)
          .then((response) => {
            let fromDateTime = new Date(response.modify_datetime);
            this.modify =
              fromDateTime.toString() + " by " + response.modify_user;
            response.configs.forEach((i) => {
              fromDateTime = new Date(i.datetime);
              this.configDataList.push({
                config_id: i.id,
                config_displayName: i.display_name,
                config_affectGameVersion:
                  i.affect_from_version + " - " + i.affect_to_version,
                config_datetime: fromDateTime.toString(),
                config_bundlename: i.filename,
                config_uploader: i.uploader,
                config_activated: i.activated,
              });
            });

            Message({
              message: "Delete Config Data success!",
              type: "success",
              duration: 5 * 1000,
            });

            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },

    // get config name list
    getConfigNameList() {
      this.loading = true;
      this.configNameList = [];

      getConfigNameListFromFirebase()
        .then((response) => {
          let config_name_list = response.config_name_list;
          config_name_list.forEach((i) => {
            this.configNameList.push({
              displayName: i.display_name,
              fileType: i.file_type,
              description: i.description,
            });
          });
          this.loading = false;
        })
        .catch((error) => {
          Message({
            message: "Can't fetch config name list",
            type: "error",
            duration: 5 * 1000,
          });
          this.loading = false;
        });
    },

    // add config name
    onAddConfigName() {
      if (isStringEmpty(this.addConfigNameForm.displayName)) {
        Message({
          message: "Name is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (isStringEmpty(this.selectedFileType)) {
        Message({
          message: "File type is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      this.loading = true;

      addConfigNameToFirebase({
        displayName: this.addConfigNameForm.displayName,
        fileType: this.selectedFileType,
        description: this.addConfigNameForm.description,
      })
        .then((response) => {
          this.configNameList = [];
          let config_name_list = response.config_name_list;
          config_name_list.forEach((i) => {
            this.configNameList.push({
              displayName: i.display_name,
              fileType: i.file_type,
              description: i.description,
            });
          });

          this.loading = false;
          Message({
            message: "Added Success!",
            type: "success",
            duration: 5 * 1000,
          });

          this.addConfigNameForm.displayName = "";
          this.addConfigNameForm.configName = "";
          this.addConfigNameForm.description = "";
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    // delete row
    onDeleteConfigName(row) {
      let count = -1;
      let rowID = 0;

      this.configNameList.forEach((i) => {
        count++;
        if (i.displayName === row.displayName) {
          rowID = count;
        }
      });

      MessageBox.confirm(
        "Do you confirm delete this Config Name: " + row.displayName + "?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;

        deleteConfigNameFromFirebase(rowID)
          .then((response) => {
            this.configNameList = [];
            let config_name_list = response.config_name_list;
            config_name_list.forEach((i) => {
              this.configNameList.push({
                displayName: i.display_name,
                configName: i.config_name,
                fileType: i.file_type,
                description: i.description,
              });
            });

            Message({
              message: "Delete success!",
              type: "success",
              duration: 5 * 1000,
            });

            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },

    // handle Remove and change Browse File
    handleRemove(file, fileList) {
      this.uploadFile = undefined;
    },
    handleChange(file, fileList) {
      this.uploadFile = file;
    },

    // upload config file
    onUploadConfigFile() {
      if (isStringEmpty(this.selectedName)) {
        Message({
          message: "Please select Config Name",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      let rowID = -1;
      let count = -1;

      this.configNameList.forEach((i) => {
        count++;
        if (i.displayName == this.selectedName) {
          rowID = count;
        }
      });

      if (rowID < 0) {
        Message({
          message: "Please select Config Name!",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (isStringEmpty(this.fromVersion)) {
        Message({
          message: "Please select From Version",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (isStringEmpty(this.toVersion)) {
        Message({
          message: "Please select From Version",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (this.uploadFile === undefined) {
        Message({
          message: "Please browse a config file!",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      this.loading = true;
      let fileData = undefined;

      var reader = new FileReader();
      reader.readAsArrayBuffer(this.uploadFile.raw);
      reader.onload = (event) => {
        fileData = event.target.result;

        if (fileData === undefined) {
          Message({
            message: "Can't parsing file to upload",
            type: "error",
            duration: 5 * 1000,
          });

          this.loading = false;
          return;
        }

        uploadConfigFileToFirebase(
          fileData,
          rowID,
          this.fromVersion,
          this.toVersion,
          store.getters.email,
          this.uploadFile.name
        )
          .then((response) => {
            Message({
              message: "Upload Success!",
              type: "success",
              duration: 5 * 1000,
            });
            this.configDataList = [];

            let fromDateTime = new Date(response.modify_datetime);
            this.modify =
              fromDateTime.toString() + " by " + response.modify_user;

            response.configs.forEach((i) => {
              fromDateTime = new Date(i.datetime);
              this.configDataList.push({
                config_id: i.id,
                config_displayName: i.display_name,
                config_affectGameVersion:
                  i.affect_from_version + " - " + i.affect_to_version,
                config_datetime: fromDateTime.toString(),
                config_bundlename: i.filename,
                config_uploader: i.uploader,
                config_activated: i.activated,
              });
            });

            this.selectedName = "";
            this.fromVersion = "";
            this.toVersion = "";

            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      };

      reader.onerror = (event) => {
        Message({
          message: "File uploading failed " + event,
          type: "error",
          duration: 5 * 1000,
        });
      };
    },

    // others
    handleClick() {},
  },
};
</script>

<style lang="scss" scoped>
.dynamic_configs-container {
  margin: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
</style>
